import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import { DefaultLayout } from "../layouts";
import { Header, Background, PageWrapper } from "../components";
import { white } from "../utils";

const Content = styled.div`
  margin: 20px;
  p > img {
    max-width: 100%;
  }
`;

export default ({ data }) => {
  const post = data.markdownRemark;
  return (
    <DefaultLayout title={post.frontmatter.title}>
      <Background image={data.logo.publicURL}>
          <Header color={white}>{post.frontmatter.title}</Header>
      </Background>
        <PageWrapper>
          <Content dangerouslySetInnerHTML={{ __html: post.html }} />
        </PageWrapper>
    </DefaultLayout>
  );
};

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
    }
    logo: file(name: { eq: "about-us" }) {
      publicURL
    }
  }
`;
